import React, { useState, useEffect } from "react"
import { PropagateLoader } from "react-spinners"
import { css } from "glamor"
import { Row, Col, Table } from "reactstrap"
import { Select, MenuItem } from "@material-ui/core"
import "react-datepicker/dist/react-datepicker.css"
import Pagination from "@material-ui/lab/Pagination"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { fetch_get } from "../../utils/fetch"
import _ from "lodash"
import Gravatar from "./gravatar"
import ModalComponent from "../../components/modal/modal"
import Jobs from "./pipeline-jobs"
import { FaRegEye, FaThumbsUp, FaThumbsDown } from "react-icons/fa"
const Pipelines = props => {
  const { selectedMonth, monthsList, project, index } = props
  const { getAccessTokenSilently } = useAuth0()
  const [pageLoading, setPageLoading] = useState(true)
  const [gotError, setGotError] = useState(false)
  const [pipelines, setPipelines] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchCounter, setSearchCounter] = useState(0)
  const [noPipelines, setNoPipelines] = useState("There are no pipelines.")
  const [selectPipeline, setSelectPipeline] = useState({})
  useEffect(() => {
    if (searchCounter === 0) return
    let url = `CI/pipelines/${
      project.project_or_personal
    }/cost?page=${currentPage}&limit=${pageSize}&type=${project.type}&parentOrganization=${
      project.parent_organization
    }${_.isNil(index) ? `` : `&index=${index}`}`
    url = filterCondition(url)
    setPageLoading(true)
    setGotError(false)
    getAccessTokenSilently()
      .then(accessToken => fetch_get(url, accessToken))
      .then(response => {
        setPipelines(response["data"] || [])
        const totalRecords = response["pagination"]["totalRecords"] || 0
        const pageSize = response["pagination"]["pageSize"] || 10
        setPageCount(Math.ceil(totalRecords / pageSize))
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
        setPipelines([])
        setNoPipelines("An error occurred while getting the list of latest pipelines.")
        Sentry.captureException(error)
        console.log(`Error while getting latest pipelines: ${error}`)
      })
  }, [searchCounter])

  useEffect(() => {
    setCurrentPage(1)
    setSelectPipeline({})
    setSearchCounter(n => n + 1)
  }, [project])

  const filterCondition = url => {
    if (selectedMonth !== undefined && selectedMonth !== null) {
      var month = monthsList.find(obj => obj.value === selectedMonth)
      url += `&startDate=${month.firstDay}&endDate=${month.lastDay}`
    }
    return url
  }

  function onPageChange(event, page) {
    setCurrentPage(page)
    setSearchCounter(n => n + 1)
  }

  function onPageSizeChange(event) {
    const size = parseInt(event.target.value, 10)
    setPageSize(size)
    setCurrentPage(1)
    setSearchCounter(n => n + 1)
  }

  const showDetail = item => {
    const { rcModal } = props
    setSelectPipeline(item)
    rcModal.showModal({
      title: "Job List",
      width: 1200,
      component: (
        <Jobs pipeline={item} index={index} selectedMonth={selectedMonth} monthsList={monthsList} />
      ),
    })
  }

  return (
    <div style={{ padding: "0px 16px 16px 16px" }}>
      <div style={{ position: "relative" }}>
        {pageLoading ? (
          <Col
            className="d-flex justify-content-center align-items-center"
            xs={{ size: 12 }}
            {...css(styles.loader)}
          >
            <PropagateLoader
              className="align-self-center"
              size={20}
              color={"#9c3"}
              loading={true}
            />
          </Col>
        ) : (
          <></>
        )}
        <div style={{ opacity: pageLoading ? ".5" : "1" }}>
          {!pageLoading && gotError ? (
            <p>
              An error has occurred while trying to retrieve the latest pipelines. If this
              continues, please raise a support ticket.
            </p>
          ) : (
            <>
              <div id="pipelinesWrapper">
                {pipelines.length > 0 && (
                  <>
                    <div style={{ fontWeight: 600, fontSize: 26, position: "absolute" }}>
                      Pipeline List
                    </div>
                    <div className="float-right">
                      <div className="form-inline">
                        <Pagination
                          count={pageCount}
                          page={currentPage}
                          onChange={onPageChange}
                          variant="outlined"
                          shape="rounded"
                        />

                        <Select
                          labelId="pageSize"
                          id="page-size"
                          value={pageSize}
                          onChange={onPageSizeChange}
                          className="pageSizeSelect"
                        >
                          <MenuItem value={10}>10 / page</MenuItem>
                          <MenuItem value={50}>50 / page</MenuItem>
                          <MenuItem value={100}>100 / page</MenuItem>
                        </Select>
                      </div>
                    </div>
                    <br />
                    <br />
                  </>
                )}
                <Row>
                  <Col>
                    <div className="table-border hover" style={{ overflow: "auto" }}>
                      <Table>
                        <thead>
                          <tr>
                            <th>Pipeline ID</th>
                            <th>Status</th>
                            <th>Repository</th>
                            <th>Cost</th>
                            <th>Triggered by</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pipelines.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                style={{ cursor: 'pointer' }}
                                className={selectPipeline.pipeline_id === item.pipeline_id ? "active" : ""}
                                onClick={() => showDetail(item)}
                              >
                                <td><a
                                    target="_blank"
                                    href={item.pipeline_url}
                                    onClick={e => e.stopPropagation()}
                                  >
                                    <FaRegEye />
                                </a>&nbsp;<a className="linkJump handlePointer">{item.pipeline_id}</a>
                                </td>
                                <td>
                                  {item.pipeline_status === "failed" && (
                                    <>
                                      <FaThumbsDown style={{ fill: "red" }} />
                                      &nbsp;
                                    </>
                                  )}
                                  {item.pipeline_status === "success" && (
                                    <>
                                      <FaThumbsUp style={{ fill: "green" }} />
                                      &nbsp;
                                    </>
                                  )}
                                  {_.startCase(item.pipeline_status)}
                                </td>
                                <td className="word-break-all">
                                  <a
                                    target="_blank"
                                    href={item.repo_path}
                                    onClick={e => e.stopPropagation()}
                                  >
                                    {item.repository}
                                  </a>
                                </td>
                                <td>
                                  $<strong>{new Number(item.cost).toFixed(2)}</strong>
                                </td>
                                <td>
                                    {item.triggered_by}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
                {pipelines.length > 0 && (
                  <div className="float-right">
                    <div className="form-inline">
                      <Pagination
                        count={pageCount}
                        page={currentPage}
                        onChange={onPageChange}
                        variant="outlined"
                        shape="rounded"
                      />
                      <Select
                        labelId="pageSize"
                        id="page-size"
                        value={pageSize}
                        onChange={onPageSizeChange}
                        className="pageSizeSelect"
                      >
                        <MenuItem value={10}>10 / page</MenuItem>
                        <MenuItem value={50}>50 / page</MenuItem>
                        <MenuItem value={100}>100 / page</MenuItem>
                      </Select>
                    </div>
                  </div>
                )}
                {!pageLoading && pipelines.length === 0 && (
                  <Row>
                    <Col xs={{ size: 12 }}>
                      <p {...css(styles.noData)}>{noPipelines}</p>
                    </Col>
                  </Row>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ModalComponent()(Pipelines)

const styles = {
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 99,
    display: "block",
    width: "100%",
    height: "100%",
  },
}

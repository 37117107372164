import React from "react"
import { Breadcrumb, BreadcrumbItem } from "reactstrap"
import { Link } from "gatsby"

const Breadcrumbs = props => {
  return (
    <div>
      <Breadcrumb>
        <div
          className="container"
          style={{
            display: "flex",
            alignSelf: "flex-end",
            paddingBottom: "1rem",
            fontSize: "20px",
          }}
        >
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          {props.breadcrumbs.map((i, index) => (
            <BreadcrumbItem key={index}>
              {i.path ? <Link to={i.path}>{i.label}</Link> : i.label}
            </BreadcrumbItem>
          ))}
        </div>
      </Breadcrumb>
    </div>
  )
}

export default Breadcrumbs

import React, { useState, useEffect } from "react"
import { change_gravatar } from "../../utils/common"

const Gravatar = (props) => {
  const [pictureUrl] = useState(props.url)

  useEffect(() => {
    if (pictureUrl) {
      change_gravatar("garvatar" + props.pipelinesId, pictureUrl)
    }
  }, [pictureUrl])

  return <img style={{height: 18}} id={"garvatar" + props.pipelinesId} src={pictureUrl}></img>
}

export default Gravatar

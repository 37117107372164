import React, { useState, useEffect } from "react"
import Content from "../../components/content/content"
import { PropagateLoader } from "react-spinners"
import { css } from "glamor"
import { Row, Col, Table } from "reactstrap"
// import { Select, MenuItem } from "@material-ui/core"
import "react-datepicker/dist/react-datepicker.css"
// import Pagination from "@material-ui/lab/Pagination"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { fetch_get } from "../../utils/fetch"
import _ from "lodash"
import moment from "moment"

const Pipelines = props => {
  const { selectedMonth, monthsList, pipeline, index } = props
  const { getAccessTokenSilently } = useAuth0()
  const [pageLoading, setPageLoading] = useState(false)
  const [gotError, setGotError] = useState(false)
  const [jobs, setJobs] = useState([])
  // const [pageCount, setPageCount] = useState(1)
  // const [currentPage, setCurrentPage] = useState(1)
  // const [pageSize, setPageSize] = useState(10)
  const [noJobs, setNoJobs] = useState("There are no jobs.")

  useEffect(() => {
    let url = `CI/jobs/${pipeline.pipeline_id}`
    url = filterCondition(url)
    url = url + `${_.isNil(index) ? `` : `&index=${index}`}`
    setPageLoading(true)
    setGotError(false)
    getAccessTokenSilently()
      .then(accessToken => fetch_get(url, accessToken))
      .then(response => {
        setJobs(response || [])
        // const totalRecords = response["pagination"]["totalRecords"] || 0
        // const pageSize = response["pagination"]["pageSize"] || 10
        // setPageCount(Math.ceil(totalRecords / pageSize))
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
        setJobs([])
        setNoJobs("An error occurred while getting the list of latest jobs.")
        Sentry.captureException(error)
        console.log(`Error while getting latest jobs: ${error}`)
      })
  }, [])

  const filterCondition = url => {
    if (selectedMonth !== undefined && selectedMonth !== null) {
      var month = monthsList.find(obj => obj.value === selectedMonth)
      url += `?startDate=${month.firstDay}&endDate=${month.lastDay}`
    }
    return url
  }

  // function onPageChange (event, page) {
  //   setCurrentPage(page)
  // }

  // function onPageSizeChange (event) {
  //   const size = parseInt(event.target.value, 10)
  //   setPageSize(size)
  //   setCurrentPage(1)
  // }

  return (
    <div style={{ padding: "0px 16px 16px 16px", maxHeight: 800, overflowY: "auto" }}>
      {pageLoading ? (
        <Col
          className="d-flex justify-content-center align-items-center"
          xs={{ size: 12 }}
          {...css(styles.loader)}
        >
          <PropagateLoader className="align-self-center" size={20} color={"#9c3"} loading={true} />
        </Col>
      ) : (
        <></>
      )}
      <div>Pipeline ID：{pipeline.pipeline_id}</div>
      <div style={{ opacity: pageLoading ? ".5" : "1" }}>
        {!pageLoading && gotError ? (
          <p>
            An error has occurred while trying to retrieve the latest jobs. If this continues,
            please raise a support ticket.
          </p>
        ) : (
          <>
            <div id="pipelinesWrapper">
              {/* {jobs.length > 0 && (
                        <>
                          <div className="float-right">
                            <div className="form-inline">
                              <Pagination
                                count={pageCount}
                                page={currentPage}
                                onChange={onPageChange}
                                variant="outlined"
                                shape="rounded"
                              />

                              <Select
                                labelId="pageSize"
                                id="page-size"
                                value={pageSize}
                                onChange={onPageSizeChange}
                                className="pageSizeSelect"
                              >
                                <MenuItem value={10}>10 / page</MenuItem>
                                <MenuItem value={50}>50 / page</MenuItem>
                                <MenuItem value={100}>100 / page</MenuItem>
                              </Select>
                            </div>
                          </div>
                          <br />
                          <br />
                        </>
                      )} */}
              <Row>
                <Col>
                  <div className="table-border" style={{ overflow: "auto" }}>
                    <Table>
                      <thead>
                        <tr>
                          <th>Job ID</th>
                          <th>Name</th>
                          <th>Stages</th>
                          <th>Cost</th>
                          <th>Duration (Minutes)</th>
                          <th>Started Date</th>
                          <th>Finished Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobs.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td><a target="_blank" href={item.id_link}>{item.job_id}</a></td>
                              <td>{item.name || "-"}</td>
                              <td>{item.stages}</td>
                              <td>$<strong>{new Number(item.cost).toFixed(2)}</strong></td>
                              <td>{item.duration}</td>
                              <td>
                                {item.started_date
                                  ? moment(item.started_date).format("YYYY-MM-DD HH:mm:ss")
                                  : "-"}
                              </td>
                              <td>
                                {item.finished_date
                                  ? moment(item.finished_date).format("YYYY-MM-DD HH:mm:ss")
                                  : "-"}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
              {/* {jobs.length > 0 && (
                        <div className="float-right">
                          <div className="form-inline">
                            <Pagination
                              count={pageCount}
                              page={currentPage}
                              onChange={onPageChange}
                              variant="outlined"
                              shape="rounded"
                            />
                            <Select
                              labelId="pageSize"
                              id="page-size"
                              value={pageSize}
                              onChange={onPageSizeChange}
                              className="pageSizeSelect"
                            >
                              <MenuItem value={10}>10 / page</MenuItem>
                              <MenuItem value={50}>50 / page</MenuItem>
                              <MenuItem value={100}>100 / page</MenuItem>
                            </Select>
                          </div>
                        </div>
                      )} */}
              {!pageLoading && jobs.length === 0 && (
                <Row>
                  <Col xs={{ size: 12 }}>
                    <p {...css(styles.noData)}>{noJobs}</p>
                  </Col>
                </Row>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Pipelines

const styles = {
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 99,
    display: "block",
    width: "100%",
    height: "100%",
  },
}

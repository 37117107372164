import React, { useState, useEffect } from "react"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import { Loading } from "../../components/loading"
import { PropagateLoader } from "react-spinners"
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs"
import { css } from "glamor"
import { Row, Col, Table } from "reactstrap"
import { Select, MenuItem, FormControl } from "@material-ui/core"
import "react-datepicker/dist/react-datepicker.css"
import Pagination from "@material-ui/lab/Pagination"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { fetch_get } from "../../utils/fetch"
import _ from "lodash"
import { useUserOrgs } from "../../hooks/use-user"
import { getMonthYearList } from "../../utils/common"
import ModalComponent from "../../components/modal/modal"
import Pipelines from "../../components/ci/project-pipelines"
import { BiSortDown, BiSortUp } from "react-icons/bi"

const Dashboard = props => {
  const { getAccessTokenSilently } = useAuth0()
  const [pageLoading, setPageLoading] = useState(true)
  const [gotError, setGotError] = useState(false)
  const [pipelines, setPipelines] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [noPipelines, setNoPipelines] = useState("There are no projects.")
  const [searchCounter, setSearchCounter] = useState(0)
  const monthsList = getMonthYearList()
  monthsList.sort((a, b) => (parseInt(a.key) < parseInt(b.key) ? 1 : -1))
  if (monthsList[0] !== undefined && monthsList[0] !== null)
    monthsList[0].value += ` | Current Month to date`
  if (monthsList[1] !== undefined && monthsList[1] !== null)
    monthsList[1].value += ` | Previous Month`
  const qm = monthsList[0].value
  const [selectedMonth, setSelectedMonth] = useState(qm)
  const [selectedOriginalMonth, setSelectedOriginalMonth] = useState(qm)
  const [selectProject, setSelectProject] = useState(null)
  const [parentOrgs, setParentOrgs] = useState([])
  const [selectParentOrg, setSelectParentOrg] = useState(null)
  const [selectOriginalParentOrg, setSelectOriginalParentOrg] = useState(null)
  const [parentOrgsLoading, setParentOrgsLoading] = useState(true)
  const [order, setOrder] = useState("desc")
  const [isOperate, setIsOperate] = useState(false)
  const {
    loading,
    currentOrg: {
      index,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()

  useEffect(() => {
    if (loading) return
    if (!_.isNil(index)) {
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(`CI/user/enabled?index=${index}`, accessToken)
            .then(response => {
              setIsOperate(response.ci_enabled)
              setParentOrgsLoading(false)
            })
            .catch(error => {
              setParentOrgsLoading(false)
              console.log(`Error while getting projects have CI enabled: ${error}`)
            })
        )
        .catch(error => {
          setParentOrgsLoading(false)
          console.log(`Error while getting acdcess token: ${error}`)
        })
      return
    }
    getAccessTokenSilently()
      .then(accessToken => fetch_get(`admin/parentOrganization/list`, accessToken))
      .then(response => {
        // var porgs = [{ id: 0, name: "All" }, ...response.porgs]
        var porgs = [...response.porgs]
        setParentOrgs(porgs)
        setSelectParentOrg(porgs[0]?.name)
        setSelectOriginalParentOrg(porgs[0]?.name)
        setParentOrgsLoading(false)
      })
  }, [loading])

  useEffect(() => {
    if (loading) return
    if (parentOrgsLoading) return
    let url = `CI/pipelines/month/cost?order=${order}&page=${currentPage}&limit=${pageSize}${
      _.isNil(index) ? `&parentOrganization=${selectOriginalParentOrg}` : `&index=${index}`
    }`
    url = filterCondition(url)
    setPageLoading(true)
    setGotError(false)
    setNoPipelines("There are no projects.")
    getAccessTokenSilently()
      .then(accessToken => fetch_get(url, accessToken))
      .then(response => {
        setPipelines(response["data"] || [])
        const totalRecords = response["pagination"]["totalRecords"] || 0
        const pageSize = response["pagination"]["pageSize"] || 10
        setPageCount(Math.ceil(totalRecords / pageSize))
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
        setPipelines([])
        setNoPipelines("An error occurred while getting the list of latest pipelines.")
        Sentry.captureException(error)
        console.log(`Error while getting latest pipelines: ${error}`)
      })
  }, [searchCounter, loading, parentOrgsLoading])

  const filterCondition = url => {
    if (selectedOriginalMonth !== undefined && selectedOriginalMonth !== null) {
      var month = monthsList.find(obj => obj.value === selectedOriginalMonth)
      url += `&startDate=${month.firstDay}&endDate=${month.lastDay}`
    }
    return url
  }

  const handleMonthChange = event => {
    setSelectedMonth(event.target.value)
  }

  function onPageChange(event, page) {
    setCurrentPage(page)
    setSearchCounter(n => n + 1)
  }

  function onPageSizeChange(event) {
    const size = parseInt(event.target.value, 10)
    setPageSize(size)
    setCurrentPage(1)
    setSearchCounter(n => n + 1)
  }

  const handleSort = type => {
    setCurrentPage(1)
    setOrder(type)
    setSelectProject(null)
    setSearchCounter(n => n + 1)
  }
  const showDetail = item => {
    const { rcModal } = props
    // if (item.type === "user") return
    setSelectProject(item)
    // rcModal.showModal({
    //   title: "Pipeline List",
    //   width: 1200,
    //   component: (
    //     <Pipelines
    //       project={item}
    //       index={index}
    //       selectedMonth={selectedOriginalMonth}
    //       monthsList={monthsList}
    //     />
    //   ),
    // })
  }
  if (loading) {
    return <Loading />
  }

  return (
    <Layout pageTitle="CI Dashboard">
      {parentOrgsLoading && (
        <Col
          className="d-flex justify-content-center align-items-center"
          xs={{ size: 12 }}
          {...css(styles.loader)}
        >
          <PropagateLoader className="align-self-center" size={20} color={"#9c3"} loading={true} />
        </Col>
      )}
      {!parentOrgsLoading && !isSuperAdmin && !isOperate && (
        <Content>
          <div className="alert alert-danger" role="alert">
            <strong>Access Denied</strong>
          </div>
        </Content>
      )}
      {!parentOrgsLoading && (isSuperAdmin || isOperate) && (
        <>
          <Breadcrumbs breadcrumbs={[{ label: "CI Dashboard" }]} />
          <Header contentPage={true} className="contentPage">
            <h1 className="display-4 font-weight-bold">CI Dashboard</h1>
          </Header>
          <Content>
            <div style={{ marginBottom: 8 }}>
              Welcome to the CodeLinaro CI Dashboard! From here, you can see a listing of all
              projects within your organization which contain pipelines and how many pipelines they
              contain.
            </div>
            <Row className="mb-2">
              <Col xs={{ size: 4 }}>
                <FormControl className="mb-2" style={{ width: "100%" }}>
                  <Select value={selectedMonth} onChange={handleMonthChange}>
                    {monthsList.map((p, index) => (
                      <MenuItem key={p.key} value={p.value} style={{ height: 36 }}>
                        {p.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              {_.isNil(index) && (
                <Col xs={{ size: 4 }}>
                  <span className="">
                    <strong>Parent organization: &nbsp;&nbsp;</strong>
                  </span>
                  <select
                    className="form-control form-control-sm"
                    style={{ display: "unset", width: "unset" }}
                    value={selectParentOrg}
                    aria-label="Default select example"
                    name="copyRightHolderOption"
                    onChange={e => {
                      setSelectParentOrg(e.target.value)
                    }}
                  >
                    {parentOrgs.map((p, index) => (
                      <option key={"porg" + index} value={p.name}>
                        {p.name}
                      </option>
                    ))}
                  </select>
                </Col>
              )}
              <Col xs={{ size: 1 }}>
                <FormControl className="mb-2" style={{ width: "100%" }}>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      setSelectedOriginalMonth(selectedMonth)
                      setSelectOriginalParentOrg(selectParentOrg)
                      setSelectProject(null)
                      setCurrentPage(1)
                      setSearchCounter(n => n + 1)
                    }}
                  >
                    <strong>Go</strong>
                  </button>
                </FormControl>
              </Col>
            </Row>
            <div style={{ display: "flex" }}>
              <div style={{ position: "relative", width: "30%" }}>
                {pageLoading ? (
                  <Col
                    className="d-flex justify-content-center align-items-center"
                    xs={{ size: 12 }}
                    {...css(styles.loader)}
                  >
                    <PropagateLoader
                      className="align-self-center"
                      size={20}
                      color={"#9c3"}
                      loading={true}
                    />
                  </Col>
                ) : (
                  <></>
                )}
                <div style={{ opacity: pageLoading ? ".5" : "1" }}>
                  {!pageLoading && gotError ? (
                    <p>
                      An error has occurred while trying to retrieve the latest pipelines. If this
                      continues, please raise a support ticket.
                    </p>
                  ) : (
                    <>
                      <div id="pipelinesWrapper">
                        {pipelines.length > 0 && (
                          <>
                            <div className="float-right">
                              <div className="form-inline">
                                <Pagination
                                  count={pageCount}
                                  page={currentPage}
                                  onChange={onPageChange}
                                  variant="outlined"
                                  shape="rounded"
                                />

                                <Select
                                  labelId="pageSize"
                                  id="page-size"
                                  value={pageSize}
                                  onChange={onPageSizeChange}
                                  className="pageSizeSelect"
                                >
                                  <MenuItem value={10}>10 / page</MenuItem>
                                  <MenuItem value={50}>50 / page</MenuItem>
                                  <MenuItem value={100}>100 / page</MenuItem>
                                </Select>
                              </div>
                            </div>
                            <br />
                            <br />
                          </>
                        )}
                        <Row>
                          <Col>
                            <div className="table-border" style={{ overflow: "auto" }}>
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Project / Personal</th>
                                    {/* <th>Type</th> */}
                                    <th>
                                      Pipelines
                                      <BiSortUp
                                        color={order === "asc" ? "#9c3" : ""}
                                        className="filter-table"
                                        onClick={() => handleSort("asc")}
                                      />
                                      <BiSortDown
                                        color={order === "desc" ? "#9c3" : ""}
                                        className="filter-table"
                                        onClick={() => handleSort("desc")}
                                      />
                                    </th>
                                    {/* <th>Cost (Month to date)</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {pipelines.map((item, index) => {
                                    return (
                                      <tr
                                        className={
                                          selectProject &&
                                          selectProject.project_or_personal ===
                                            item.project_or_personal &&
                                          selectProject.parent_organization ===
                                            item.parent_organization
                                            ? "active"
                                            : ""
                                        }
                                        key={index}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => showDetail(item)}
                                      >
                                        <td>
                                          <a className="linkJump handlePointer">
                                            {item.project_or_personal}
                                          </a>
                                        </td>
                                        {/* <td>{item.type}</td> */}
                                        <td>{item.total_pipelines}</td>
                                        {/* <td>
                                          $<strong>{new Number(item.cost).toFixed(2)}</strong>
                                        </td> */}
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                        {pipelines.length > 0 && (
                          <div className="float-right">
                            <div className="form-inline">
                              <Pagination
                                count={pageCount}
                                page={currentPage}
                                onChange={onPageChange}
                                variant="outlined"
                                shape="rounded"
                              />
                              <Select
                                labelId="pageSize"
                                id="page-size"
                                value={pageSize}
                                onChange={onPageSizeChange}
                                className="pageSizeSelect"
                              >
                                <MenuItem value={10}>10 / page</MenuItem>
                                <MenuItem value={50}>50 / page</MenuItem>
                                <MenuItem value={100}>100 / page</MenuItem>
                              </Select>
                            </div>
                          </div>
                        )}
                        {!pageLoading && pipelines.length === 0 && (
                          <Row>
                            <Col xs={{ size: 12 }}>
                              <p {...css(styles.noData)}>{noPipelines}</p>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div style={{ width: "100%" }}>
                {selectProject && (
                  <Pipelines
                    project={selectProject}
                    index={index}
                    selectedMonth={selectedOriginalMonth}
                    monthsList={monthsList}
                  />
                )}
              </div>
            </div>
          </Content>
        </>
      )}
    </Layout>
  )
}

export default ModalComponent()(Dashboard)

const styles = {
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 99,
    display: "block",
    width: "100%",
    height: "100%",
  },
}

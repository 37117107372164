import React, { Fragment, useState } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import { css } from "glamor"

const RcModal = (options = { propKey: "rcModal" }) => {
  // eslint-disable-next-line func-names
  return function (WrappedComponent) {
    const RcModalHelper = props => {
      const [visible, setVisible] = useState(false)
      const [title, setTitle] = useState("")
      const [component, setComponent] = useState(null)
      const [width, setWidth] = useState(500)

      const showModal = options => {
        const { title = "", component = null, width = 500 } = options
        setVisible(true)
        setTitle(title)
        setComponent(component)
        setWidth(width)
      }

      const closeModal = () => {
        setVisible(false)
      }
      const rcModal = {
        closeModal: closeModal.bind(this),
        showModal: showModal.bind(this),
      }
      const modalProps = {
        [options.propKey]: rcModal,
      }
      const componentProps = component ? component.props : {}
      const Component = component ? component.type : () => null
      const key = component ? component.key : undefined
      const nextProps = {
        ...props,
        ...modalProps,
      }
      return (
        <Fragment>
          <WrappedComponent {...nextProps} />
          <Modal
            isOpen={visible}
            backdrop="static"
            zIndex={1500}
            size="xl"
            centered={true}
            {...css({ width })}
          >
            <ModalHeader toggle={() => closeModal()}>{title}</ModalHeader>
            <ModalBody {...css({ padding: "2rem 0 0 0" })}>
              <Component {...componentProps} key={key} {...modalProps} />
            </ModalBody>
          </Modal>
        </Fragment>
      )
    }
    return RcModalHelper
  }
}

export default RcModal
